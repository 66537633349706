/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Swiper */
@import '~swiper/swiper-bundle.css';

.my-popover-wide {
    @media only screen and (max-width: 320px) { --min-width: 95%; } 
    @media only screen and (min-width: 320px) { --min-width: 95%; }
    @media only screen and (min-width: 400px) { --min-width: 95%; }
    @media only screen and (min-width: 500px) { --min-width: 80%; } 
    @media only screen and (min-width: 600px) { --min-width: 60%; }
}

.messageBox .alert-message {
    text-align: left;
    @media only screen and (max-width: 320px) { font-size: 15px; color: black; } 
    @media only screen and (min-width: 320px) { font-size: 16px; color: black; }
    @media only screen and (min-width: 400px) { font-size: 17px; color: black; }
    @media only screen and (min-width: 500px) { font-size: 18px; color: black; } 
    @media only screen and (min-width: 600px) { font-size: 19px; color: black; }
}