// Jura light
@font-face {
    font-family: Jura;
    src: url(../assets/fonts/Jura/Jura-Light.ttf) format("truetype");
    font-style: normal;
    font-weight: 300;
}

// Jura Regular
@font-face {
    font-family: Jura;
    src: url(../assets/fonts/Jura/Jura-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
}

// Jura Medium
@font-face {
    font-family: Jura;
    src: url(../assets/fonts/Jura/Jura-Medium.ttf) format("truetype");
    font-style: normal;
    font-weight: 500;
}

// Jura SemiBold
@font-face {
    font-family: Jura;
    src: url(../assets/fonts/Jura/Jura-SemiBold.ttf) format("truetype");
    font-style: normal;
    font-weight: 600;
}

// Jura Bold
@font-face {
    font-family: Jura;
    src: url(../assets/fonts/Jura/Jura-Bold.ttf) format("truetype");
    font-style: normal;
    font-weight: 700;
}
